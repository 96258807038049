
const baseurl = "https://encryption.edukrypt.app/lmsapi";
const apikey = "3cd92916ebaea0b3f52dd83f5e8a1800";
const domain = "https://www.aadityaguptaclasses.com";




export {
    baseurl,
    apikey,
    domain
} ;