import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CgProfile } from "react-icons/cg";

const Topbar = ()=>{
    const response = useSelector((state)=> state.websiteReducer.data);
    const isLogin = useSelector((state)=> state.loginReducer.isLogin);
    const navbar = useSelector((state)=> state.navbarReducer);
    const [navData,setNavData] = useState(null);

    useEffect(()=>{

        
        
        getNavData();
        
        
    },[]);
    
    const getNavData = ()=>{
       
        
            if(navbar.data.status ===1)
            {
                setNavData(navbar.data.data);
            }

            console.log(navbar);
    }

    return (
        <>
            <div className="topbar shadow-lg">
                <div className='container'>
                    <div className="row flex justify-between">
                        <div className="md:col-4 lg:col-4">
                            <div>
                                <Link to="/">
                                <img src={response.status===1 ? response.data.site_logo : ''} className='logo-agc' alt="logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="md:col-6 lg:col-6 flex items-center justify-end w-full px-4">
                            <ul className="list-none footer-list flex justify-end gap-6">
                                <li className="hover:text-gray-400 duration-500 ease-in-out pt-1"><Link to="/">Home</Link></li>
                                <li className="hover:text-gray-400 duration-500 ease-in-out mr-4 pt-1"><Link to="/courses">Course</Link></li>
                                {
                                    navData ?
                                    <li className="hover:text-gray-400 duration-500 ease-in-out mr-4 pt-1"><Link to={"/page/"+(navData ? navData.slug : '')}>{navData ? navData.blog_title : ''}</Link></li>
                                    :
                                    ""
                                }
                                
                               {
                                isLogin ?
                                <li className="hover:text-white duration-500 ease-in-out text-orange-400 hover:text-orange-700 text-2xl "><Link to="/profile"><CgProfile /></Link></li>
                                :
                                <li className="hover:text-white duration-500 ease-in-out bg-orange-400 hover:bg-orange-700 text-white px-2 py-1 shadow-sm rounded-sm"><Link to="/login">Login</Link></li>
                               }
                            </ul>
                        </div>
                        <div className="md:col-2 lg:col-2 d-flex align-items-center">
                            <ul className="list-none footer-list d-flex justify-content-end">
                                
                                <li><a className=" hover:text-orange-400 duration-500 ease-in-out inline-flex items-center mr-4" href="https://www.facebook.com/aadityaguptaclasses/" target='_blank'><i className="fa fa-facebook" aria-hidden="true"></i>       </a></li>
                                <li><a className=" hover:text-orange-400 duration-500 ease-in-out inline-flex items-center mr-4" href="https://www.youtube.com/c/AadityaGupta"  target='_blank'><i className="fa fa-youtube" aria-hidden="true"></i>       </a></li>
                                <li><a className=" hover:text-orange-400 duration-500 ease-in-out inline-flex items-center mr-4" href="https://www.instagram.com/aadityaguptaclasses/?utm_medium=copy_link"  target='_blank'><i className="fa fa-instagram" aria-hidden="true"></i>       </a></li>
                                <li><a className=" hover:text-orange-400 duration-500 ease-in-out inline-flex items-center mr-4" href="https://x.com/i/flow/login?redirect_after_login=%2FClassesAaditya"  target='_blank'><i className="fa fa-twitter" aria-hidden="true"></i>       </a></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
                
            
        </>
    );
}

export default Topbar;