import {
    NAV_ERROR,
    NAV_REQUEST,
    NAV_SUCCESS,
    PRIVACY_REQUEST,
    PRIVACY_SUCCESS,
    PRIVACY_ERROR,
    PAGE_ERROR,
    PAGE_REQUEST,
    PAGE_SUCCESS
} from "./Navbar.state";

const Model = {
    isLoading:false,
    error:null,
    data:[]
}
const navbarReducer = (state=Model,action)=>{

    switch(action.type)
    {
        case NAV_REQUEST: return {
            ...state,
            isLoading:true

        }

        case NAV_SUCCESS : return {
            ...state,
            isLoading:false,
            data:action.payload
        }
        case NAV_ERROR : return {
            ...state,
            isLoading:false,
            error:action.error
        }


        default : return state;
    }

}

const policyReducer = (state=Model,action)=>{
    switch(action.type)
    {
        
        case PRIVACY_ERROR : return {
            ...state,
            isLoading:false,
            error:action.error
        }
        case PRIVACY_SUCCESS : return {
            ...state,
            isLoading:false,
            data:action.payload
        }
        case PRIVACY_REQUEST: return {
            ...state,
            isLoading:true

        }


        default : return state;
    }
}

const dynamicPageReducer = (state=Model,action)=>{
    switch(action.type)
    {
        
        case PAGE_ERROR : return {
            ...state,
            isLoading:false,
            error:action.error
        }
        case PAGE_SUCCESS : return {
            ...state,
            isLoading:false,
            data:action.payload
        }
        case PAGE_REQUEST: return {
            ...state,
            isLoading:true

        }


        default : return state;
    }
}

export{
    navbarReducer,
    policyReducer,
    dynamicPageReducer
} ;