import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Navbar from '../../../component/Navbar/navbar'
import Footer from '../../../component/Footer/footer';
import Topbar from '../../../component/Navbar/Topbar';
import "../../../../node_modules/react-modal-video/css/modal-video.css";
import TinySlider from 'tiny-slider-react';
import "tiny-slider/dist/tiny-slider.css";
import Cookies from "universal-cookie";
import Loader from "../../Loader/Loader";
import { useSelector} from 'react-redux';

const settings = {
  container: '.tiny-single-item',
  items: 2,
  controls: false,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  speed: 400,
  gutter: 16,
}

export default function IndexLandingFour() {
  
  const [loading, setLoading] = useState(true);
  const response = useSelector((state) => state.websiteReducer);
  const [sectionsArray, setSectionsArray] = useState();
  const [webData, setWebData] = useState();
  const [siteData, setSiteData] = useState();
  const cookie = new Cookies();


  useEffect(() => {
        
    if (response && response.data && response.data.data) {
        setWebData(response.data.data);
      setSectionsArray(response.data.data.home_sections);
      setLoading(false); // Set loading to false when data is fetched
    }

    if(cookie.get('agc_favicon'))
    {
         setSiteData(cookie.get("agc_favicon"));
    }

    
  }, [response]);

  if (loading) {
    return (
        <Loader />
        
      );
  }

    



  const AboutUs = React.memo(()=>{
    const design = (
        <>
            <section className="relative pt-2 pb-24  dark:bg-slate-800 mt-7">
                <div className="container relative md:mt-24 mt-16">
                    <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
                        <div className="relative">
                            <div className="md:me-10">
                                <img src='images/course/aboutus.png' className="" alt="" />
                                <h5 className="mb-6 md:text-2xl md:leading-normal text-2xl text-center leading-normal font-semibold">Discover the AGC advantage today!</h5>

                            </div>
                            
                        </div>

                        <div className="lg:ms-8 mt-8 md:mt-0">
                            <h4 className="mb-4 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">About AGC  </h4>
                            <p className="text-slate-400 text-justify">{webData ? webData.home_about_us: ''}</p>
                            
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

    return design;
})

const PopularCourses = React.memo(()=>{
    return (
        <>
            <section className="relative md:py-24 py-16 overflow-hidden" id="courses">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Popular Courses</h3>
                        <p className="text-slate-400 max-w-xl mx-auto"></p>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-8 gap-[30px]">

                        { webData ?
                            webData.popular_courses.map((item,index)=>{
                                return (
                                    <div key={index} className="group relative rounded-md shadow hover:shadow-lg dark:shadow-gray-800 duration-500 ease-in-out overflow-hidden">
                            
                                        <Link to={"/course_details/"+item.slug}>
                                            <div className="relative overflow-hidden">
                                                <img src={item.image} className="group-hover:scale-110 duration-500 ease-in-out" alt="course_img"/>
                                                <div className="absolute inset-0 bg-slate-900/50 opacity-0 group-hover:opacity-100 duration-500 ease-in-out"></div>
            
                                                <div className="absolute start-0 bottom-0 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                                                    <div className="pb-4 ps-4 flex items-center">
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                        <div className="content p-6 relative">
                                            <a href="/" className="font-medium block text-indigo-600">{item.main_category}</a>
                                            <a href="/" className="text-lg font-medium block hover:text-indigo-600 duration-500 ease-in-out mt-2"> {item.product_name}</a>
                                            <Link to={"/course_details/"+item.slug} type="submit" id="submitsubscribe" name="send" className=" mt-5 py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-orange-400 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md">Buy now</Link>
                                            <div className="absolute -top-7 end-6 z-1 price_btn group-hover:opacity-100 duration-500 ease-in-out d-block"  >
                                                {/*<div className="flex justify-center items-center size-14 bg-white dark:bg-slate-900 rounded-full shadow-lg dark:shadow-gray-800 text-indigo-600 dark:text-white" >
                                                    
                                                </div>*/}
                                            </div>
                                        </div>
                                    
                                    </div>
                                )
                            })
                            : null
                        }
                       
                    </div>

                </div>
            </section>
        </>
    );
})

const Testimonial = React.memo(()=>{
    return (
        <>
            <section className="relative md:py-24 py-16 overflow-hidden">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Our Community</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">See What Our Students Have to Say!!</p>
                    </div>
                    <div className="flex justify-center">
                        <div className="grid grid-cols-2 lg:grid-cols-1 md:grid-cols-1 mt-8 gap-[30px] ">
                                

                                    <TinySlider settings={settings}>
                                    {

                                        webData ? webData.testimonials.map((item, index) => {
                                            return (
                                                <div className="tiny-slide tiny-single-item" key={index}>
                                                    <div className="grid gird-cols-1 p-6 lg:p-0 relative rounded-md shadow dark:shadow-gray-800 overflow-hidden m-2">
                                                        <div className="flex justify-start items-center gap-4 p-6">
                                                            <div>
                                                                <img className="lg:rounded-full rounded-full mx-auto h-24 w-24" src={item.image ? item.image : ''} alt="student" />
                                                            </div>
                                                            <div>
                                                                <span className="text-indigo-600 block mb-1">{item.username}</span>
                                                                <span className="text-slate-400 text-sm dark:text-white/60 block">{item.designation}</span>
                                                            </div>
                                                        </div>
                                                        <div className="pt-6 lg:p-6 text-center lg:text-start space-y-4">
                                                            <p className="text-base text-slate-400">{item.text}</p>
                                                            
                                                            {/* Display rating with colored hearts */}
                                                            <div className="rating">
                                                                {Array.from({ length: 5 }, (v, i) => (
                                                                    <span key={i} style={{ color: i < item.rating ? '#ff0000' : '#cccccc' }}>
                                                                        &#10084; {/* Heart icon */}
                                                                    </span>
                                                                ))}
                                                            </div>

                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : null
                                    }
                                    </TinySlider>
                                
                            
                        </div>
                    </div>

                    
                </div>
            </section>
        </>
    );
})

const Instructor = React.memo(()=>{
    return (
        <>
            <div className="container relative md:mt-24 mt-16 mb-12" id="Instructors">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Our Instructor</h3>

                    <p className="text-slate-400 max-w-xl mx-auto">Meet Our Experienced Faculty Dedicated to Your Success</p>
                </div>

                <div className="grid md:grid-cols-12 grid-cols-1 mt-8 gap-[30px] our-instructor">
                    <div className="lg:col-span-3  m-auto justify-content-center">
                        <div className="group text-center">
                            <div className="relative inline-block mx-auto size-52 rounded-full overflow-hidden">
                                <img src="images/course/profile.jpeg" className="" alt=""/>
                                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black size-52 rounded-full opacity-0 group-hover:opacity-100 duration-500"></div>
                            </div>

                            <div className="content mt-3">
                                <a href="/" className="text-lg font-semibold hover:text-indigo-600 duration-500">CA Himanshu Grover</a>
                                <p className="text-slate-400">Professor</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
})



    return (
        <>
        

            <Topbar />
            <Navbar />

            
            <section className="relative table w-full bg-banner" id="home">
                    <div className="lg:col-span-12">
                        <img src='images/course/bg4.png' alt="" className=''/>

                          
                        </div>
            </section>




        { sectionsArray ? sectionsArray.includes('aboutus') && (
                <AboutUs />
            ) : ''}    
         


            { sectionsArray ? sectionsArray.includes('popular_courses') && (
                <PopularCourses />

            ) : ''} 
            { sectionsArray ? sectionsArray.includes('testimonials') && (
                <Testimonial />
            ) : ''}

            
            

            { sectionsArray ? sectionsArray.includes('instructor') && (
                <Instructor />
            ) : ''}

            <Footer />
            {/* <CookieModal /> */}
        </>
    )
}
