import {
    NAV_ERROR,
    NAV_REQUEST,
    NAV_SUCCESS,
    PRIVACY_REQUEST,
    PRIVACY_SUCCESS,
    PRIVACY_ERROR,
    PAGE_ERROR,
    PAGE_REQUEST,
    PAGE_SUCCESS
} from "./Navbar.state";

import axios from "axios";
import {baseurl, apikey, domain } from '../../../baseurl'; 
axios.defaults.baseURL = baseurl;


const navbarFetch = (page)=>{
        return async (dispatch)=>
        {
            dispatch({
                type:NAV_REQUEST,
                payload:[]
            });
            try {
                const response = await axios.get(
                    '/page/'+page, 
                {
                    headers: {
                    'apikey': apikey,
                    'domain': domain,
                    }
                }
                );
               
                dispatch({
                    type:NAV_SUCCESS,
                    payload:response.data
                });
            } 
            catch (error) 
            {
                // Consider logging the error or handling it differently
                
                dispatch({
                    type:NAV_ERROR,
                    error:error
                })
            }
        }
}

const privacy_policy = (page)=>{
    return async (dispatch)=>
    {
        dispatch({
            type:PRIVACY_REQUEST,
            payload:[]
        });
        try {
            const response = await axios.get(
                '/page/'+page, 
            {
                headers: {
                'apikey': apikey,
                'domain': domain,
                }
            }
            );
           
            dispatch({
                type:PRIVACY_SUCCESS,
                payload:response.data
            });
        } 
        catch (error) 
        {
            // Consider logging the error or handling it differently
            
            dispatch({
                type:PRIVACY_ERROR,
                error:error
            })
        }
    }
}

const dynamic_page = (page)=>{
    return async (dispatch)=>
    {
        dispatch({
            type:PAGE_REQUEST,
            payload:[]
        });
        try {
            const response = await axios.get(
                '/page/'+page, 
            {
                headers: {
                'apikey': apikey,
                'domain': domain,
                }
            }
            );
           
            dispatch({
                type:PAGE_SUCCESS,
                payload:response.data
            });
        } 
        catch (error) 
        {
            // Consider logging the error or handling it differently
            
            dispatch({
                type:PAGE_ERROR,
                error:error
            })
        }
    }
}

export{
    navbarFetch,
    privacy_policy,
    dynamic_page
}