import axios from "axios";
import {baseurl, apikey, domain } from '../../baseurl'; // Assuming baseurl is not needed if using axios.defaults.baseURL

// Set the base URL for all axios requests (if applicable)
axios.defaults.baseURL = baseurl;

const product_category = async () => {
  try {
    const response = await axios.get(
      '/main-category',
      {
        headers: {
          'apikey': apikey,
          'domain': domain,
        }
      }
    );
    return response;
  } catch (error) {
    // Consider logging the error or handling it differently
    
    return error || { status: 500, data: { message: 'An unexpected error occurred.' } };
  }
};

const courses = async (cat_data) => {
  var url = "";
  if(cat_data.category_id)
  {
    url =  '/course-list?category_slug='+cat_data.category_id+"&page="+cat_data.page;
  }
  else
  {
    url =  '/course-list?page='+cat_data.page
  }
  try {
    const response = await axios.get(
      url,
      {
        headers: {
          'apikey': apikey,
          'domain': domain,
        }
      }
    );
    return response;
  } catch (error) {
    // Consider logging the error or handling it differently
    
    return error || { status: 500, data: { message: 'An unexpected error occurred.' } };
  }
};

const course_content = async (p_data) => {
  try {
    const response = await axios.post(
      '/course-content',
      p_data,
      {
        headers: {
          'apikey': apikey,
          'domain': domain,
        }
      }
    );
    return response;
  } catch (error) {
    // Consider logging the error or handling it differently
    
    return error || { status: 500, data: { message: 'An unexpected error occurred.' } };
  }
};

const price_list = async (product) => {
  try {
    const response = await axios.post(
      '/course-detail-price',
      product,
      {
        headers: {
          'apikey': apikey,
          'domain': domain,
        }
      }
    );
    return response;
  } catch (error) {
    // Consider logging the error or handling it differently
    
    return error || { status: 500, data: { message: 'An unexpected error occurred.' } };
  }
};



export {
    product_category,
    courses,
    course_content,
    price_list
};