import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';

import logo_light from '../../assets/images/agc.webp';

import { MdKeyboardArrowRight} from '../../assets/icons/icons';
import { useSelector } from 'react-redux';

export default function Footer() {
   
    const policyData = useSelector(response=>response)

    useEffect(()=>{
       //console.log(policyData);
    },[]);


    return (
    <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
        <div className="container relative">
            <div className="grid grid-cols-12">
                <div className="col-span-12">
                    <div className=" px-0 pt-16 pb-5">
                        <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                            <div className="lg:col-span-5 md:col-span-12">
                                <Link to="/#" className="text-[22px] focus:outline-none">
                                    <img src={logo_light ? logo_light : ''} alt="logo" className='width30'/>
                                </Link>
                                <p className="mt-6 text-gray-300 text-justify">Join and experience the new style of education<br/> with Aaditya Gupta Classes at the next level.</p>
                            
                            </div>


                            <div className="lg:col-span-3 md:col-span-4">
                                <h5 className="tracking-[1px] text-gray-100 font-semibold ms-2">Useful Links</h5>
                                <ul className="list-none footer-list mt-6">
                                        <li><Link to='/page/terms-conditions' className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight className="text-xl me-1"/> Terms & Condition </Link></li>
                                        <li><Link to='/page/privacy-policy' className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center mr-1"><MdKeyboardArrowRight className="text-xl me-1"/> Privacy Policy</Link></li>
                                        <li><Link to='/gallery' className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center mr-1"><MdKeyboardArrowRight className="text-xl me-1"/> Gallery</Link></li>
                                        <li><Link to='/blog' className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center mr-1"><MdKeyboardArrowRight className="text-xl me-1"/> Blogs</Link></li>
                                   
                                </ul>
                            </div>

                            <div className="lg:col-span-4 md:col-span-4">
                                <h5 className="tracking-[1px] text-gray-100 font-semibold">Info</h5>
                                {/* <p className="mt-6">AGC NEW SUKHDEV NAGAR, NEAR DK SINGH HOSPITAL, PANIPAT-132103 (HARYANA)</p> */}
                                <form>
                                    <div className="grid grid-cols-1">
                                    <div className="my-3 mt-5">
                                                <label className="form-label">
                                                    <a href="mailto:aadityagupta692@gmail.com">Email id - aadityagupta692@gmail.com</a>
                                                </label>

                                                <label className="form-label" >
                                                    <a href="tel:9903503989">Mobile No. - 9903503989</a>
                                                </label>
                                               
                                            </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="py-[30px] px-0 border-t border-slate-800">
            <div className="container relative text-center">
                <div className="grid md:grid-cols-2 items-center">
                    <div className="md:text-start text-center">
                        <p className="mb-0">Copyright © 2022 Design By AGC .</p>
                    </div>

                </div>
            </div>
        </div>
    </footer>
    )
}
