import {
    WEB_REQUEST,
    WEB_FETCH,
    WEB_ERROR
} from "./website_page.state";

const Model = {
    isLoading:false,
    error:null,
    data:[]
}
const websiteReducer = (state=Model,action)=>{

    switch(action.type)
    {
        case WEB_REQUEST : return {
            ...state,
            isLoading:true
        }

        case WEB_FETCH : return {
            ...state,
            isLoading:false,
            data:action.payload
        }
        case WEB_ERROR : return {
            ...state,
            isLoading:false,
            data:action.error
        }

        default : return state;
    }

}

export default websiteReducer;