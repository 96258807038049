import React from 'react';
import Cookies from "universal-cookie";

const cookie = new Cookies();
const isLogin = cookie.get('icp_authData') ? true : false;
const isOtpVerify = cookie.get('icp_otpVerify') ? true : false;
const isReset = cookie.get('icp_forgot_otp') ? true : false;


export {
    isLogin,
    isOtpVerify,
    isReset
}