import {
    WEB_REQUEST,
    WEB_FETCH,
    WEB_ERROR
} from "./website_page.state";

import axios from "axios";
import {baseurl, apikey, domain } from '../../baseurl'; 
axios.defaults.baseURL = baseurl;

const webRequest = ()=>{
        return async (dispatch)=>
        {
            dispatch({
                type:WEB_REQUEST,
                payload:[]
            });
            try {
                const response = await axios.get(
                '/website-setting',
                {
                    headers: {
                    'apikey': apikey,
                    'domain': domain,
                    }
                }
                );
                dispatch({
                    type:WEB_FETCH,
                    payload:response.data
                });
            } 
            catch (error) 
            {
                // Consider logging the error or handling it differently
                
                dispatch({
                    type:WEB_ERROR,
                    error:error
                })
            }
        }
}


export {
    webRequest
}