import React from "react";
import { LuLoader2 } from "react-icons/lu";

const Loader = ()=>{
    return (
        <>
            <div className="flex py-24 justify-center">
                <LuLoader2   className="animate-spin text-6xl text-blue-700" />
                
            </div>
        </>
    );
}

export default Loader;