const NAV_REQUEST = "NAV_REQUEST";
const NAV_SUCCESS = "NAV_SUCCESS";
const NAV_ERROR = "NAV_ERROR";
const PRIVACY_REQUEST = "PRIVACY_REQUEST";
const PRIVACY_SUCCESS = "PRIVACY_SUCCESS";
const PRIVACY_ERROR = "PRIVACY_ERROR";
const PAGE_REQUEST = "PAGE_REQUEST";
const PAGE_SUCCESS = "PAGE_SUCCESS";
const PAGE_ERROR = "PAGE_ERROR";


export {
    NAV_ERROR,
    NAV_REQUEST,
    NAV_SUCCESS,
    PRIVACY_REQUEST,
    PRIVACY_SUCCESS,
    PRIVACY_ERROR,
    PAGE_ERROR,
    PAGE_REQUEST,
    PAGE_SUCCESS
}