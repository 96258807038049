import React from 'react'
import '../../assets/libs/@mdi/font/css/materialdesignicons.min.css';
import '../../assets/css/tailwind.css';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
import { product_category } from '../../axios/api/product_category';


export default function Navbar(props) {
    let { navClass, navJustify } = props;
    let [isMenu, setisMenu] = useState(false);
    let [manu , setManu] = useState('');
    let location = useLocation();
    const [category, setCategory] = useState();
    
    useEffect(()=>{
        let current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        setManu(current)

        function windowScroll() {
            const navbar = document.getElementById("topnav");
            if (
                document.body.scrollTop >= 50 ||
                document.documentElement.scrollTop >= 50
            ) {
                if (navbar !== null) {
                    navbar?.classList.add("nav-sticky");
                }
            } else {
                if (navbar !== null) {
                    navbar?.classList.remove("nav-sticky");
                }
            }
        }
        window.addEventListener("scroll", windowScroll);
        window.scrollTo(0, 0)
        return () => {
            window.removeEventListener('scroll', windowScroll);
        };

    },[location.pathname.substring(location.pathname.lastIndexOf('/') + 1)]);


    useEffect(()=>{

        get_category();
    },[]);

    const get_category = async ()=>{
       const p_category =  await product_category();
       if(p_category.status === 200)
       {
          setCategory(p_category.data.data);
       }
    }
    

    const toggleMenu = () => {
        setisMenu(!isMenu);
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    };


    return (
        <nav id="topnav" className={`defaultscroll ${navClass === "nav-light" ? '' : navClass === "nav-sticky" ?
        'bg-white dark:bg-slate-900' : ''}`}>
        <div className=" relative">
         

            <div className="menu-extras">
                <div className="menu-item">
                    <Link to="#" className={`navbar-toggle ${isMenu ? 'open' : ''}`} id="isToggle" onClick={() => toggleMenu()}>
                        <div className="lines">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </Link>
                </div>
            </div>

            
           
            <div id="navigation" style={{ display: isMenu ? 'block' : 'none' }}>
                <ul className={`navigation-menu ${navClass} ${navJustify}`}>
                

                    <li className={`${["page-aboutus", "page-services","page-team", "page-pricing","page-testimonial","user-profile","user-billing","user-payment","user-invoice","user-notification","user-setting","property-listing","property-detail","course-listing","course-detail","nft-explore","nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail","food-recipe","shop-grid","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","photography-about","photography-portfolio","page-job-grid","page-job-detail","page-job-apply","page-job-post","page-job-career","page-job-candidates","page-job-candidate-detail","page-job-companies","page-Job-company-detail","forums-topic","forums-comments","helpcenter-overview","helpcenter-faqs","helpcenter-guides","helpcenter-support","blog","blog-sidebar","blog-detail","blog-standard-post","blog-slider-post","blog-gallery-post","blog-youtube-post","blog-vimeo-post","blog-audio-post","blog-blockquote-post","blog-left-sidebar-post","email-confirmation","email-password-reset","email-alert","email-invoice","auth-login","auth-signup","auth-re-password","auth-lock-screen","page-terms","page-privacy","page-comingsoon","page-maintenance","page-error","page-thankyou","contact-detail","contact-one","contact-two"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`}>
                        <Link to="" className='header-menu'>About Us</Link>
                        {/* <span className="menu-arrow"></span> */}

                        <ul className="submenu d-block visiblity-visible opacity-1">
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to=""> Description</Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to=""> Free Courses</Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to=""> Courses </Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to=""> Test Series </Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to=""> Books </Link></li>
                     
                        </ul>
                       
                    </li>
                

                    <li className={`${["page-aboutus", "page-services","page-team", "page-pricing","page-testimonial","user-profile","user-billing","user-payment","user-invoice","user-notification","user-setting","property-listing","property-detail","course-listing","course-detail","nft-explore","nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail","food-recipe","shop-grid","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","photography-about","photography-portfolio","page-job-grid","page-job-detail","page-job-apply","page-job-post","page-job-career","page-job-candidates","page-job-candidate-detail","page-job-companies","page-Job-company-detail","forums-topic","forums-comments","helpcenter-overview","helpcenter-faqs","helpcenter-guides","helpcenter-support","blog","blog-sidebar","blog-detail","blog-standard-post","blog-slider-post","blog-gallery-post","blog-youtube-post","blog-vimeo-post","blog-audio-post","blog-blockquote-post","blog-left-sidebar-post","email-confirmation","email-password-reset","email-alert","email-invoice","auth-login","auth-signup","auth-re-password","auth-lock-screen","page-terms","page-privacy","page-comingsoon","page-maintenance","page-error","page-thankyou","contact-detail","contact-one","contact-two"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`}>
                        <Link to="" className='header-menu'>Free Resources</Link>
                        {/* <span className="menu-arrow"></span> */}
                        <ul className="submenu visiblity-visible opacity-1 mr-2">
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/page/bcom"> B. COM</Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/page/class-11-12">Class 11-12 </Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/page/stock-market">Stock Market </Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/page/ms-excel">MS-Excel </Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/page/ms-powerpoint">MS-Powerpoint </Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/page/ms-word">MS-Word </Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/page/spoken-english">Spoken English </Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/page/personality-development">Personality Developement </Link></li>
                            
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/page/past-year-papers-solved">Past Year Paper (Solved) </Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="">Books </Link></li>

                 

                     
                        </ul>
                    </li>

                    <li className={`${["page-aboutus", "page-services","page-team", "page-pricing","page-testimonial","user-profile","user-billing","user-payment","user-invoice","user-notification","user-setting","property-listing","property-detail","course-listing","course-detail","nft-explore","nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail","food-recipe","shop-grid","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","photography-about","photography-portfolio","page-job-grid","page-job-detail","page-job-apply","page-job-post","page-job-career","page-job-candidates","page-job-candidate-detail","page-job-companies","page-Job-company-detail","forums-topic","forums-comments","helpcenter-overview","helpcenter-faqs","helpcenter-guides","helpcenter-support","blog","blog-sidebar","blog-detail","blog-standard-post","blog-slider-post","blog-gallery-post","blog-youtube-post","blog-vimeo-post","blog-audio-post","blog-blockquote-post","blog-left-sidebar-post","email-confirmation","email-password-reset","email-alert","email-invoice","auth-login","auth-signup","auth-re-password","auth-lock-screen","page-terms","page-privacy","page-comingsoon","page-maintenance","page-error","page-thankyou","contact-detail","contact-one","contact-two"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`}>
                        <Link to="" className='header-menu'>B.COM</Link>
                        {/* <span className="menu-arrow"></span> */}
                        <ul className="bcom-menu ">

                           
                            <li 
                           
                            className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}>
                                <Link to="/category/semester-1">SEM I</Link>
                            </li>
                            <li 
                           
                            className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}>
                                <Link to="/category/semester-2">SEM II</Link>
                            </li>
                            <li 
                           
                            className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}>
                                <Link to="/category/semester-3">SEM III</Link>
                            </li>
                            <li 
                           
                            className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}>
                                <Link to="/category/semester-4">SEM IV</Link>
                            </li>
                            <li 
                           
                            className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}>
                                <Link to="/category/semester-v">SEM V</Link>
                            </li>
                            <li 
                           
                            className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}>
                                <Link to="/category/semester-vi">SEM VI</Link>
                            </li>
                            <li 
                           
                            className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}>
                                <Link to="/category/semester-vii">SEM VII</Link>
                            </li>
                            <li 
                           
                            className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}>
                                <Link to="/category/semester-viii">SEM VIII</Link>
                            </li>
                                    
                            
                            
                     
                        </ul>
                    </li>
                    <li className={`${["page-aboutus", "page-services","page-team", "page-pricing","page-testimonial","user-profile","user-billing","user-payment","user-invoice","user-notification","user-setting","property-listing","property-detail","course-listing","course-detail","nft-explore","nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail","food-recipe","shop-grid","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","photography-about","photography-portfolio","page-job-grid","page-job-detail","page-job-apply","page-job-post","page-job-career","page-job-candidates","page-job-candidate-detail","page-job-companies","page-Job-company-detail","forums-topic","forums-comments","helpcenter-overview","helpcenter-faqs","helpcenter-guides","helpcenter-support","blog","blog-sidebar","blog-detail","blog-standard-post","blog-slider-post","blog-gallery-post","blog-youtube-post","blog-vimeo-post","blog-audio-post","blog-blockquote-post","blog-left-sidebar-post","email-confirmation","email-password-reset","email-alert","email-invoice","auth-login","auth-signup","auth-re-password","auth-lock-screen","page-terms","page-privacy","page-comingsoon","page-maintenance","page-error","page-thankyou","contact-detail","contact-one","contact-two"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`}>
                        <Link to="" className='header-menu'>Stock Market</Link>
                        {/* <span className="menu-arrow"></span> */}
                        <ul className="submenu visiblity-visible opacity-1 right-30">
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/course/amateur"> Amateur</Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/course/pro">Pro </Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/course/legendary">Legendary </Link></li>
                        </ul>
                    </li>


                    <li className={`${["page-aboutus", "page-services","page-team", "page-pricing","page-testimonial","user-profile","user-billing","user-payment","user-invoice","user-notification","user-setting","property-listing","property-detail","course-listing","course-detail","nft-explore","nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail","food-recipe","shop-grid","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","photography-about","photography-portfolio","page-job-grid","page-job-detail","page-job-apply","page-job-post","page-job-career","page-job-candidates","page-job-candidate-detail","page-job-companies","page-Job-company-detail","forums-topic","forums-comments","helpcenter-overview","helpcenter-faqs","helpcenter-guides","helpcenter-support","blog","blog-sidebar","blog-detail","blog-standard-post","blog-slider-post","blog-gallery-post","blog-youtube-post","blog-vimeo-post","blog-audio-post","blog-blockquote-post","blog-left-sidebar-post","email-confirmation","email-password-reset","email-alert","email-invoice","auth-login","auth-signup","auth-re-password","auth-lock-screen","page-terms","page-privacy","page-comingsoon","page-maintenance","page-error","page-thankyou","contact-detail","contact-one","contact-two"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`}>
                        <Link to="" className='header-menu'>Class 11-12</Link>
                        {/* <span className="menu-arrow"></span> */}
                        <ul className="bcom-menu ">
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/category/single-subject">Single Subject </Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/category/any-3-subject">Any 3 Subject</Link></li>
                           
                     
                        </ul>
                    </li>

                    <li className={`${["page-aboutus", "page-services","page-team", "page-pricing","page-testimonial","user-profile","user-billing","user-payment","user-invoice","user-notification","user-setting","property-listing","property-detail","course-listing","course-detail","nft-explore","nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail","food-recipe","shop-grid","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","photography-about","photography-portfolio","page-job-grid","page-job-detail","page-job-apply","page-job-post","page-job-career","page-job-candidates","page-job-candidate-detail","page-job-companies","page-Job-company-detail","forums-topic","forums-comments","helpcenter-overview","helpcenter-faqs","helpcenter-guides","helpcenter-support","blog","blog-sidebar","blog-detail","blog-standard-post","blog-slider-post","blog-gallery-post","blog-youtube-post","blog-vimeo-post","blog-audio-post","blog-blockquote-post","blog-left-sidebar-post","email-confirmation","email-password-reset","email-alert","email-invoice","auth-login","auth-signup","auth-re-password","auth-lock-screen","page-terms","page-privacy","page-comingsoon","page-maintenance","page-error","page-thankyou","contact-detail","contact-one","contact-two"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`}>
                        <Link to="" className='header-menu'>Test Series</Link>
                        {/* <span className="menu-arrow"></span> */}
                        <ul className="submenu visiblity-visible opacity-1">
                        <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="category/test-semester-i">Sem I</Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/category/test-semester-ii">Sem II</Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/category/test-semester-iii">Sem III</Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/category/test-semester-iv">Sem IV</Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/category/test-semester-v">Sem V</Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/category/test-semester-vi">Sem VI</Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/category/test-semester-vii">Sem VII</Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/category/test-semester-viii">Sem VIII</Link></li>                    
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/category/test-class-11-12">Class 11-12</Link></li>                    
                        </ul>
                    </li>

                    <li className={`${["page-aboutus", "page-services","page-team", "page-pricing","page-testimonial","user-profile","user-billing","user-payment","user-invoice","user-notification","user-setting","property-listing","property-detail","course-listing","course-detail","nft-explore","nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail","food-recipe","shop-grid","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","photography-about","photography-portfolio","page-job-grid","page-job-detail","page-job-apply","page-job-post","page-job-career","page-job-candidates","page-job-candidate-detail","page-job-companies","page-Job-company-detail","forums-topic","forums-comments","helpcenter-overview","helpcenter-faqs","helpcenter-guides","helpcenter-support","blog","blog-sidebar","blog-detail","blog-standard-post","blog-slider-post","blog-gallery-post","blog-youtube-post","blog-vimeo-post","blog-audio-post","blog-blockquote-post","blog-left-sidebar-post","email-confirmation","email-password-reset","email-alert","email-invoice","auth-login","auth-signup","auth-re-password","auth-lock-screen","page-terms","page-privacy","page-comingsoon","page-maintenance","page-error","page-thankyou","contact-detail","contact-one","contact-two"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`}>
                        <Link to="" className='header-menu'>Books</Link>
                        {/* <span className="menu-arrow"></span> */}
                        <ul className="submenu visiblity-visible opacity-1">
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/category/e-book"> Ebook</Link></li>
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/category/hardcopy"> HardCopy  </Link></li>
                            
                     
                        </ul>
                    </li>
                   
                   
                </ul>
                
            </div>
        </div >
    </nav >
    )
}
